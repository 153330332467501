<template>
  <div class="record-search bg-light">
    <div class="btn-group">
      <input v-on:keyup="searchRecord" v-model="search" class="form-control" :placeholder="placeholder" v-if="isRealTime">
      <input v-on:keyup.enter="searchRecord" v-model="search" class="form-control" :placeholder="placeholder" v-else>
      <div class="btn btn-muted bg-white btn-search btn-search-end" v-if="!showSideOptions" v-on:click="searchRecord" >
        <i class="fas fa-search mt-2"></i>
      </div>
      <div class="btn btn-muted bg-white btn-search btn-search-end d-block d-lg-none d-md-none" v-if="showSideOptions" v-on:click="searchRecord" >
        <i class="fas fa-search mt-2"></i>
      </div>
      <div class="btn btn-muted bg-white btn-search d-none d-lg-block d-md-block" v-if="showSideOptions" v-on:click="searchRecord">
        <i class="fas fa-search mt-2"></i>
      </div>
      <div class="btn btn-light btn-sort d-none d-lg-block d-md-block" v-on:click="toggleSideOptions" v-if="showSideOptions" v-tooltip="`Show filters`">
        <i class="fas fa-filter mt-2"></i>
        <i v-if="hasFilters" class="fas fa-exclamation-circle text-warning icon-indicator"></i>
      </div>
    </div>
  </div>
</template>

<script>
  export default {
    props: {
      placeholder: String,
      showSideOptions: Boolean,
      isRealTime: Boolean,
      hasFilters: Boolean
    },
    data() {
      return {
        search: ''
      }
    },
    methods: {
      toggleSideOptions: function(val){
        this.$emit('toggleSideOptions', val)
      },
      searchRecord: function(){
          this.$emit('searchRecord', this.search)
      },
    },
    mounted: function() {
      if(Object.keys(this.$route.query).length > 0)
        this.toggleSideOptions()
    }
  }
</script>
