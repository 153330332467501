<template>
  <div class="list-holder">
    <li class="row" v-for="record in records" v-bind:key="record.key">
      <div class="col col-12 row list-details pointer" v-on:click="openRecord(record)">
        <div class="col col-auto-9 row left-column">
          <div class="col col-auto-3 list-avatar" v-if="record.avatar">
            <img :src="record.avatar">
          </div>
          <div class="col">
            <div class="list-header">
              {{record.header}}
            </div>
            {{record.subheader}}
            <span class="pointer" v-tooltip="formatDate(record.timestamp.createdAt)" v-if="record.timestamp.createdAt">
              | created {{record.timestamp.createdAt | dateEstimate }}
            </span>
            <div class="list-tags-holder" v-if="record.tags">
                <div v-bind:class="['list-tag bg-' + tag.background]" v-for="tag in record.tags" v-bind:key="tag.key">{{tag.text}}</div>
            </div>
          </div>
        </div>
        <div class="col col-3 right-column d-none d-lg-block d-md-block d-sm-block">
          <label v-bind:class="['bg-' + indicator.background]" v-for="indicator in record.indicators" v-bind:key="indicator.key" v-tooltip="indicator.placeholder">{{indicator.text}}</label>
          <small>
            <span class="pointer" v-tooltip="formatDate(record.timestamp.updatedAt)" v-if="record.timestamp.updatedAt">
              updated {{record.timestamp.updatedAt | dateEstimate }}
            </span>
          </small>
        </div>
      </div>
    </li>
    <li v-if="records.length === 0" class="vertical-center h-100">
      <h6 class="col col-12 text-muted text-center p-3">
        No available {{profile | wordify}} &nbsp;
        <h6 class="fas fa-exclamation-circle"></h6>
      </h6>
    </li>
  </div>
</template>

<script>
  import moment from 'moment'
  export default {
    props: {
      records: Array,
      profile: String
    },
    methods: {
      formatDate: function (date){
        return moment(date, 'YYYY/MM/DD HH:mm:ss').format("MMM DD, YYYY hh:mm A")
      },
      openRecord: function(record){
        window.location.href = `/${record.profile ? record.profile : this.profile}/${record.key}`
      }
    },
    mounted: function() {
    },
    filters: {
      dateEstimate(date) {
        return moment(date, 'YYYY/MM/DD HH:mm:ss').add({hours: 8}).fromNow();
      },
      wordify(val){
        return val.split('_').join(' ')
      }
    }
  }
</script>
